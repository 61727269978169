var main = require('integrations/main');

// Object.assign(main.baseFiles, {
//     header: require('./components/header'),
//     miniCart: require('./components/miniCart'),
//     cart: require('./cart'),
//     base: require('./product/base'),
// });

main.baseFiles.header = require('./components/header');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.cart = require('./cart');
main.baseFiles.base = require('./product/base');

module.exports = main;
