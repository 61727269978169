'use strict';

// Grab the base in autobahn_core
var baseCore = require('integrations/product/base');
var abSlider = require('core/components/slider');
var scrollAnimate = require('core/components/scrollAnimate');
var exportDetails = {};

function sizeChart() {
    $('body').on('click', '.size-chart .size-chart-launcher', event => {
        event.preventDefault();
        var url = $(event.target).attr('href');
        var modalTitle = $(event.target).text();
        var productId = $(event.target).closest('.product-detail').find('.product-id').html();
        var $sizeChartModal = $('.modal[data-product=' + productId + ']');
        var $modalBody = $sizeChartModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);

                // if slider in modal, init
                var $sliderContainer = $modalBody.find('.slider-container');
                if ($sliderContainer.length) {
                    abSlider.initializeSliders($sliderContainer.parent());
                }
            }
        });
        //if the sizechart is from a quickview append after all the modal-backdrops
        if ($(event.target).parents('.product-quickview').length) {
            $sizeChartModal.appendTo('body');
        }

        $sizeChartModal.find('.modal-title').html(modalTitle);

        $sizeChartModal.modal('show');
    });

    // custom close event to allow closing only this modal inside quickview modals
    $('body').on('click', '.size-chart-modal .close', event => {
        var productId = $(event.target).closest('.size-chart-modal').data('product');
        $('.modal[data-product=' + productId + ']').modal('hide');
    });
}

function sizeChartLinkReplace() {
    var $sizeChartLauncherLinks = $('.product-detail').find('.size-chart-launcher');

    if (!$sizeChartLauncherLinks.length) {
        return;
    }

    $sizeChartLauncherLinks.each((index, element) => {
        var $sizeChartLauncherLink = $(element);
        var pdAssetId = $sizeChartLauncherLink.attr('data-size-chart-id') ? $sizeChartLauncherLink.attr('data-size-chart-id').replace('pd-inc-', '') : null;
        var pdAssetOnPage = $('.experience-component #' + pdAssetId);

        if ($sizeChartLauncherLink.length && pdAssetOnPage.length) {
            $sizeChartLauncherLink.removeClass('size-chart-launcher').addClass('size-chart-scroll').attr('href', '#' + pdAssetId);
        }
    });

    $('body').on('click', '.size-chart .size-chart-scroll', event => {
        event.preventDefault();
        // var url = $(event.target).attr('href');
        var pdAssetId = $(event.target).attr('data-size-chart-id') ? $(event.target).attr('data-size-chart-id').replace('pd-inc-', '') : null;

        if (pdAssetId != null) {
            scrollAnimate($('#' + pdAssetId));
        }
    });
}

function updateAttribute() {
    function showPriceIfNotRange() {
        // update data attr on prices div - css shows/hides price based on dta attr value
        if ($('.product-detail .prices-add-to-cart-actions .price .range').length > 0) {
            $('.product-detail .prices-add-to-cart-actions .prices').attr('data-price-type', 'range');
        } else {
            $('.product-detail .prices-add-to-cart-actions .prices').attr('data-price-type', '');
        }
    }

    function toggleMattressRightFirmness(product) {
        // hide/show right firmness attr when sleep system twin xl is selected
        if (product.gtmData.categoryID == 'sleep-systems' || product.gtmData.categoryID == 'mattresses') {
            var variationAttributes = product.variationAttributes;
            for (var i = 0; i < variationAttributes.length; i++) {
                var variationAttribute = variationAttributes[i];
                if (variationAttribute.attributeId === 'mattress-size') {
                    var labelName = $('#mattress-firmness-left-1').siblings('.mattress-firmness-left').attr('data-default-name');
                    if (variationAttribute.selectedValue === 'TXL') {
                        if (!$('#mattress-firmness-right-1').parents('[data-attr="mattress-firmness-right"]').hasClass('d-none')) {
                            if ($('#mattress-firmness-right-1')[0].selectedIndex === 0) {
                                $('#mattress-firmness-right-1').prop('selectedIndex', 1).change();
                            }
                            $('#mattress-firmness-left-1').siblings('.mattress-firmness-left').html(labelName.replace('Left', ''));
                            $('#mattress-firmness-left-1 option:first').html(labelName.replace('Left', ''));
                            $('#mattress-firmness-right-1').parents('[data-attr="mattress-firmness-right"]').addClass('d-none');
                        }
                    } else {
                        if ($('#mattress-firmness-right-1').parents('[data-attr="mattress-firmness-right"]').hasClass('d-none')) {
                            $('#mattress-firmness-left-1').siblings('.mattress-firmness-left').html(labelName);
                            $('#mattress-firmness-left-1 option:first').html(labelName);
                            $('#mattress-firmness-right-1').parents('[data-attr="mattress-firmness-right"]').removeClass('d-none');
                        }
                    }
                    break;
                }
            }
        }
    }

    $('body').on('product:afterAttributeSelect', function (e, response) {
        //Quickview
        if ($('.modal.show .product-quickview>.bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else if ($('.set-items').length) {
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.modal.show .product-quickview').length) {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
            $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        //Main PDP
        } else if ($('.product-detail>.bundle-items').length) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.product-set-detail').eq(0)) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
            response.container.find('.add-to-cart').data('pid', response.data.product.id);
            toggleMattressRightFirmness(response.data.product);
            showPriceIfNotRange();
        } else {
            $('.product-detail .add-to-cart').data('pid', response.data.product.id);
            $('.product-id').text(response.data.product.id);
            $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
        }
    });
}

exportDetails = $.extend({}, baseCore, {
    sizeChart: sizeChart,
    sizeChartLinkReplace: sizeChartLinkReplace,
    updateAttribute: updateAttribute
});

module.exports = exportDetails;
